html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    background-color: #000000;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

a {
    background-color: transparent;
}

img {
    border-style: none;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.main {
    text-align: center;
    padding: 30px 15px;
    img.logo {
        width: 500px;
        max-width: 100%;
    }
    ul.social-links {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        li {
            display: block;
            text-align: center;
            margin: 0 5px 5px;
            padding: 0;

            @media (min-width: 768px) {
                margin: 0 10px 10px;
            }            

            a {
                display: inline-block;
                background-color: transparent;
                background-repeat: no-repeat;
                background-image: url(../images/app-icons.png);
                max-width: 100%; 
                background-size: 100%;
                height: 45px;
                width: 45px;
                @media (min-width: 768px) {
                    height: 100px;
                    width: 100px;
                }            
            }
        }

        li.bandcamp a {
            background-position: 0 0%; 
        }
        li.facebook a {
            background-position: 0 20%; 
        }
        li.instagram a {
            background-position: 0 40%; 
        }
        li.email a {
            background-position: 0 60%; 
        }
        li.spotify a {
            background-position: 0 80%; 
        }
        li.youtube a {
            background-position: 0 100%; 
        }
    }
}